import axios from 'axios';

const getUsers = () => axios.get('User/GetAll');

const getUserById = (id) => axios.get(`User/GetUserById?Id=${id}`);

const getConfirmEmail = (id) => axios.get(`User/getConfirmEmail?Id=${id}`);

const addUser = (user) => axios.post('User/AddUser', user);

const updateUser = (user) => axios.put('User/UpdateUser', user);

const updateUserProfile = (user) => axios.put('User/UpdateUserProfile', user);

const getUserDetailsById = (userId) => axios.get(`User/GetUserInfobyId?Id=${userId}`);

const changePassword = (data) => axios.post('User/ChangePassword', data);

const forgotPassword = (data) => axios.post('User/ForgotPassword', data);

const userUpdateApiKey = (userid, apiKey) => axios.post(`User/UpdateApiKey?userid=${userid}&apiKey=${apiKey}`);

const userUpdateRefreshToken = (userId, charmCode) =>
  axios.post(`User/UpdateRefreshToken?userId=${userId}&charmCode=${charmCode}`);

const changeUserFacility = (practiceId, facilityId) =>
  axios.get(`User/EnterDashboard?practiceId=${practiceId}&facilityId=${facilityId}`);

const GetUsersByPractice = (practiceId, facilityId) =>
  axios.get(`User/GetUsersByPractice?practiceId=${practiceId}&facilityId=${facilityId}`);

const GetAllUsersByPractice = (practiceId, facilityId) =>
  axios.get(`User/GetAllUsersByPractice?practiceId=${practiceId}&facilityId=${facilityId}`);

const activateUser = (email) =>
  axios.get(`User/ActivateUser?email=${email}`);

const resendEmail = (email, userId) =>
  axios.post(`User/ResendEmail?email=${email}&userId=${userId}`);

const deleteUserByID = (userId) =>
  axios.delete(`User/DeleteUserByID?Id=${userId}`);

export {
  getUsers,
  getUserById,
  getConfirmEmail,
  addUser,
  updateUser,
  updateUserProfile,
  getUserDetailsById,
  changePassword,
  forgotPassword,
  changeUserFacility,
  userUpdateApiKey,
  userUpdateRefreshToken,
  GetUsersByPractice,
  GetAllUsersByPractice,
  activateUser,
  resendEmail,
  deleteUserByID
};
