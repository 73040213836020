import axios from 'axios';

const getUserRoles = () => axios.get('UserRole/GetAllRoles');

const addUserRole = (role) => axios.post('UserRole/Add', role);

const updateUserRole = (role) => axios.put('UserRole/Update', role);

const getUserRoleByPractice = (practiceId, facilityId) =>
  axios.get(`UserRole/GetByPractice?practiceId=${practiceId}&facilityId=${facilityId}`);

const getUserRoleById = (userRoleId, practiceId, facilityId) =>
  axios.get(`UserRole/GetbyRoleId?userRoleId=${userRoleId}&practiceId=${practiceId}&facilityId=${facilityId}`);

export { getUserRoles, addUserRole, updateUserRole, getUserRoleById, getUserRoleByPractice };
