import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'Login' },
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "page-not-found" */ '../views/page-not-found.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue'),
  },
  {
    path: '/activateuser/:email',
    name: 'ActivateUser',
    component: () => import(/* webpackChunkName: "login" */ '../views/activate-user/activate-user.vue'),
  },
  {
    path: '/get_charm_code',
    name: 'CharmCode',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/CharmCodeAccess.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register/register.vue'),
  },
  {
    path: '/charm-facilities',
    name: 'CharmFacilities',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "facility-selection" */ '../views/facility-selection/charm-facilities.vue'
      ),
  },
  {
    path: '/facility-selection',
    name: 'FacilitySelection',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "facility-selection" */ '../views/facility-selection/facility-selection.vue'
      ),
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "facility-selection" */ '../views/change-password/change-password.vue'
      ),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "facility-selection" */ '../views/forgot-password/forgot-password.vue'
      ),
  },
  {
    path: '/app',
    name: 'Layout',
    component: () => import(/* webpackChunkName: "layout" */ '../views/layout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '../views/dashboard/dashboard.vue'),
        meta: {
          title: 'Dashboard',
        },
      },
      {
        path: 'suppliers',
        name: 'suppliers',
        component: () =>
          import(/* webpackChunkName: "suppliers" */ '../views/suppliers/suppliers.vue'),
        meta: {
          title: 'Suppliers',
          dashboardWidgetId: 1,
          breadcrumbs: [{ name: 'Suppliers', disabled: true }] },
      },
      {
        path: 'supplier-details',
        name: 'supplier-details',
        component: () =>
          import(
            /* webpackChunkName: "suppliers-details" */ '../views/suppliers/supplier-details.vue'
          ),
        meta: {
          title: 'Suppliers',
          dashboardWidgetId: 1,
          breadcrumbs: [
            { name: 'Suppliers', href: '/app/suppliers' },
            { name: 'Details', disabled: true },
          ],
        },
      },
      {
        path: 'inventory/:name/:viewtype',
        name: 'inventory',
        component: () =>
          import(/* webpackChunkName: "inventory" */ '../views/inventory/inventory.vue'),
        meta: {
          title: 'Inventory',
          dashboardWidgetId: 5,
          breadcrumbs: [{ name: 'Inventory', disabled: true }] },
      },
      {
        path: 'injectables',
        name: 'injectables',
        component: () =>
          import(/* webpackChunkName: "injectables" */ '../views/injectables/injectables.vue'),
        meta: {
          title: 'Injectables',
          dashboardWidgetId: 2,
          inventoryDashboardWidgetId: 5,
          breadcrumbs: [{ name: 'Injectables', disabled: true }] },
      },
      {
        path: 'injectable-details',
        name: 'injectable-details',
        component: () =>
          import(
            /* webpackChunkName: "inj-details" */ '../views/injectables/injectable-details.vue'
          ),
        meta: {
          title: 'Injectables',
          dashboardWidgetId: 2,
          breadcrumbs: [
            { name: 'Injectables', href: '/app/injectables' },
            { name: 'Details', disabled: true },
          ],
        },
      },
      {
        path: 'iv-bags',
        name: 'iv-bags',
        component: () => import(/* webpackChunkName: "iv-bags" */ '../views/iv-bags/iv-bags.vue'),
        meta: {
          dashboardWidgetId: 3,
          inventoryDashboardWidgetId: 5,
          title: 'IV Bags',
          breadcrumbs: [{ name: 'IV Bags', disabled: true }] },
      },
      {
        path: 'iv-bag-details',
        name: 'iv-bag-details',
        component: () =>
          import(/* webpackChunkName: "iv-bag-details" */ '../views/iv-bags/iv-bag-details.vue'),
        meta: {
          title: 'IV Bags',
          dashboardWidgetId: 3,
          breadcrumbs: [
            { name: 'IV Bags', href: '/app/iv-bags' },
            { name: 'Details', disabled: true },
          ],
        },
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import(/* webpackChunkName: "reports" */ '../views/reports/reports.vue'),
        meta: {
          title: 'Ivbags',
          dashboardWidgetId: 9,
          breadcrumbs: [{ name: 'Reports', disabled: true }] },
      },
      {
        path: 'base-protocols',
        name: 'base-protocols',
        component: () =>
          import(
            /* webpackChunkName: "base-protocols" */ '../views/base-protocols/base-protocols.vue'
          ),
        redirect: '/app/base-protocols/iv',
        meta: {
          dashboardWidgetId: 4,
          title: 'Base Protocols',
          breadcrumbs: [{ name: 'Base Protocols', disabled: true }] },
          children: [
            {
              path: 'iv',
              name: 'IVProtocols',
              component: () =>
                import(/* webpackChunkName: "dashboard" */ '../views/iv-protocols/iv-protocols.vue'),
              meta: {
                title: 'IVProtocols',
                dashboardWidgetId: 4,
                breadcrumbs: [{ name: 'Base Protocols', disabled: true }],
              },
            },
            {
              path: 'im',
              name: 'IMProtocols',
              component: () =>
                import(/* webpackChunkName: "dashboard" */ '../views/im-protocols/im-protocols.vue'),
              meta: {
                title: 'IMProtocols',
                dashboardWidgetId: 4,
                breadcrumbs: [{ name: 'Base Protocols', disabled: true }],
              },
            },
          ]
      },
      {
        path: 'iv-protocol-details',
        name: 'iv-protocol-details',
        component: () =>
          import(
            /* webpackChunkName: "base-protocol-details" */ '../views/iv-protocols/iv-protocol-details.vue'
          ),
        meta: {
          title: 'IvProtocols',
          dashboardWidgetId: 4,
          breadcrumbs: [{ name: 'Base Protocols', disabled: true }],
        },
      },
      {
        path: 'im-protocol-details',
        name: 'im-protocol-details',
        component: () =>
          import(
            /* webpackChunkName: "base-protocol-details" */ '../views/im-protocols/im-protocol-details.vue'
          ),
        meta: {
          title: 'IMProtocols',
          dashboardWidgetId: 4,
          breadcrumbs: [{ name: 'Base Protocols', disabled: true }],
        },
      },
      {
        path: 'prescribing',
        name: 'prescribing',
        component: () =>
          import(
            /* webpackChunkName: "iv-prescribing" */ '../views/iv-prescribing/iv-prescribing.vue'
          ),
        meta: {
          title: 'Prescribing',
          dashboardWidgetId: 7,
          breadcrumbs: [{ name: 'Prescribing', disabled: true }] },
      },
      {
        path: 'prescribing-details',
        name: 'prescribing-details',
        component: () =>
          import(
            /* webpackChunkName: "iv-prescribing-details" */ '../views/iv-prescribing/iv-prescribing-details.vue'
          ),
        meta: {
          title: 'Prescribing',
          dashboardWidgetId: 7,
          breadcrumbs: [
            { name: 'Prescribing', href: '/app/prescribing' },
            { name: 'Details', disabled: true },
          ],
        },
      },
      {
        path: 'iv-administration',
        name: 'iv-administration',
        component: () =>
          import(
            /* webpackChunkName: "iv-administration" */ '../views/iv-administration/iv-administration.vue'
          ),
        meta: {
          title: 'IV Administration',
          dashboardWidgetId: 6,
          breadcrumbs: [{ name: 'IV Administration', disabled: true }] },
      },
      {
        path: 'iv-administration-details',
        name: 'iv-administration-details',
        component: () =>
          import(
            /* webpackChunkName: "iv-admin-details" */ '../views/iv-administration/iv-administration-details.vue'
          ),
        meta: {
          title: 'IV Administration',
          dashboardWidgetId: 6,
          breadcrumbs: [
            { name: 'IV Administration', href: '/app/iv-administration' },
            { name: 'Details', disabled: true },
          ],
        },
      },
      {
        path: 'im-administration',
        name: 'im-administration',
        component: () =>
          import(
            /* webpackChunkName: "iv-administration" */ '../views/im-administration/im-administration.vue'
          ),
        meta: {
          title: 'IM Administration',
          dashboardWidgetId: 6,
          breadcrumbs: [{ name: 'IM Administration', disabled: true }] },
      },
      {
        path: 'im-administration-details',
        name: 'im-administration-details',
        component: () =>
          import(
            /* webpackChunkName: "iv-admin-details" */ '../views/im-administration/im-administration-details.vue'
          ),
        meta: {
          title: 'IM Administration',
          dashboardWidgetId: 6,
          breadcrumbs: [
            { name: 'IM Administration', href: '/app/im-administration' },
            { name: 'Details', disabled: true },
          ],
        },
      },
      {
        path: 'quick-im-administration',
        name: 'quick-im-administration',
        component: () =>
          import(
            /* webpackChunkName: "iv-administration" */ '../views/quick-im-administration/quick-im-administration-search.vue'
          ),
        meta: {
          title: 'Quick IM Administration',
          dashboardWidgetId: 6,
          breadcrumbs: [{ name: 'Quick IM Administration', disabled: true }] },
      },
      {
        path: 'quick-im-administration-details',
        name: 'quick-im-administration-details',
        component: () =>
          import(
            /* webpackChunkName: "iv-admin-details" */ '../views/quick-im-administration/quick-im-administration.vue'
          ),
        meta: {
          title: 'Quick IM Administration',
          dashboardWidgetId: 6,
          breadcrumbs: [
            { name: 'Quick IM Administration', href: '/app/quick-im-administration' },
            { name: 'Details', disabled: true },
          ],
        },
      },
      {
        path: 'users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../views/users/users.vue'),
        meta: {
          title: 'Users',
          dashboardWidgetId: 8,
          breadcrumbs: [{ name: 'Users', disabled: true }] },
      },
      {
        path: 'user-details',
        name: 'user-details',
        component: () =>
          import(/* webpackChunkName: "user-details" */ '../views/users/user-details.vue'),
        meta: {
          title: 'Users',
          dashboardWidgetId: 8,
          breadcrumbs: [
            { name: 'Users', href: '/app/users' },
            { name: 'Details', disabled: true },
          ],
        },
      },
      {
        path: 'roles',
        name: 'roles',
        component: () => import(/* webpackChunkName: "roles" */ '../views/roles/roles.vue'),
        meta: {
          dashboardWidgetId: 8,
          title: 'Roles',
          breadcrumbs: [{ name: 'Roles', disabled: true }] },
      },
      {
        path: 'role-details',
        name: 'role-details',
        component: () =>
          import(/* webpackChunkName: "role-details" */ '../views/roles/role-details.vue'),
        meta: {
          title: 'Roles',
          dashboardWidgetId: 8,
          breadcrumbs: [
            { name: 'Roles', href: '/app/roles' },
            { name: 'Details', disabled: true },
          ],
        },
      },
      {
        path: 'preferences',
        name: 'preferences',
        component: () =>
          import(/* webpackChunkName: "preferences" */ '../views/preferences/preferences.vue'),
        meta: {
          title: 'Preferences',
          dashboardWidgetId: 8,
          breadcrumbs: [{ name: 'Roles', disabled: true }] },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    const userToken = Vue.$storage.get('token');
    if (!userToken) {
      Vue.$storage.clear();
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
