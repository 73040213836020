import Vue from 'vue';
import moment from 'moment';
import VueProgressBar from 'vue-progressbar';
import Toasted from 'vue-toasted';
import VueSweetalert2 from 'vue-sweetalert2';
import { Vue2Storage } from 'vue2-storage';
import VueApexCharts from 'vue-apexcharts';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './scss/index.scss';
import UserMixin from './mixins/user-details';
import InputPatternMixin from './mixins/input-pattern';

require('./api');

Vue.use(VueSweetalert2, { animation: false, allowOutsideClick: false });
Vue.use(VueProgressBar, {
  color: '#d88200',
  failedColor: '#019814',
  thickness: '8px',
  autoRevert: true,
  autoFinish: false,
  location: 'top',
});
Vue.use(Toasted, { duration: 5000 });
Vue.use(Vue2Storage, {
  prefix: 'core-drip-',
  driver: 'local',
  ttl: 60 * 60 * 24 * 1000, // 24 hours
});
Vue.use(VueApexCharts);

Vue.component('ApexChart', VueApexCharts);
Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.mixin(UserMixin);
Vue.mixin(InputPatternMixin);
export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
