import axios from 'axios';

const getUserPractices = (userId) => axios.get(`User/GetUserPractices?userId=${userId}`);

const addFacility = (payload) => axios.post('Facility/Add', payload);

const getFacility = () => axios.get('Facility/Get');

const getFacilityById = (facilityId) => axios.get(`Facility/GetbyId?facilityId=${facilityId}`);

const getFacilities = () => axios.get('Facility/GetAll');

const getFacilitiesByPractice = (practiceId) => axios.get(`Facility/GetByPractice?practiceId=${practiceId}`);

const updateFacility = (payload) => axios.put('Facility/Update', payload);

export {
  getUserPractices,
  addFacility,
  getFacility,
  getFacilityById,
  getFacilities,
  updateFacility,
  getFacilitiesByPractice,
};
