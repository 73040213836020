<template>
  <v-app>
    <v-main class="bg">
      <router-view class="main"></router-view>
    </v-main>
    <vue-progress-bar></vue-progress-bar>
    <v-overlay :value="$store.state.showOverlay" opacity="0.2" :z-index="9999">
      <v-progress-circular
        indeterminate
        size="65"
        :width="7"
        color="button"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({}),
};
</script>

<style scoped>
.v-application {
  background-color: var(--v-background-base) !important;
}
</style>
