import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userPermissions: [],
    userPracticeName: '',
    userFacilityName: '',
    showOverlay: false,
  },
  mutations: {
    user_permissions(state, payload) {
      state.userPermissions = payload || [];
    },
    user_practice_name(state, payload) {
      state.userPracticeName = payload || [];
    },
    user_facility_name(state, payload) {
      state.userFacilityName = payload || [];
    },
    update_show_overlay(state, payLoad) {
      state.showOverlay = payLoad;
    },
  },
  actions: {
    getUserpermissions(context, payload) {
      context.commit('user_permissions', payload);
    },
    getUserPracticeName(context, payload) {
      context.commit('user_practice_name', payload);
    },
    getUserFacilityName(context, payload) {
      context.commit('user_facility_name', payload);
    },
    showOverlay(context, payload) {
      context.commit('update_show_overlay', payload);
    },
  },
  modules: {
  },
});
