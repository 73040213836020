module.exports = {
  methods: {
    onlyNumberFilter(evt, str, length) {
      evt = (evt) || window.event;
      const expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[0-9]*\.?[0-9]*$/.test(expect) || (str && str.length + 1 > length)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    },
    noDecimals(evt, str,length) {
      evt = (evt) || window.event;
      const expect = evt.target.value.toString() + evt.key.toString();
      if(evt.keyCode == 8 || evt.keyCode == 46) return true;
      if (!/^[0-9]{1,10}$/.test(expect)|| (str && str.length + 1 > length)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    },
    phoneNumberRegex(evt, str) {
      evt = (evt) || window.event;
      const expect = evt.target.value.toString() + evt.key.toString();
      if (evt.key === '-') {
        evt.preventDefault();
      }
      if (!/^[-+]?[0-9]*\.?[0-9\-]*$/.test(expect) || (str && str.length + 1 > 12)) { // eslint-disable-line
        evt.preventDefault();
      } else {
        this.formatPhoneNumber(evt);
        return true;
      }
      return false;
    },
    formatPhoneNumber(evt) {
      evt.target.value = evt.target.value.replace(/\D/g, ''); // Remove illegal characters
      if (evt.target.value.length > 2) evt.target.value = evt.target.value.replace(/.{3}/, '$&-');
      if (evt.target.value.length > 6) evt.target.value = evt.target.value.replace(/.{7}/, '$&-');
    },
    restrictDecimals(evt) {
      evt = (evt) || window.event;
      const expect = evt.target.value.toString() + evt.key.toString();
      if (evt.which !== 8 && evt.which !== 46 && !/^\d*(\.\d{0,3})?$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    },
    onlyNumberWithRestrictedDecimals(evt, str, length) {
      evt = (evt) || window.event;
      const expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)
        || (str && str.length + 1 > length)
        || (evt.which !== 8 && evt.which !== 46 && !/^\d*(\.\d{0,2})?$/.test(expect))) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    },
    alphanumericWithDotAndColon(evt) {
      evt = (evt) || window.event;
      const expect = evt ? evt.target.value.toString() + evt.key.toString() : '';
      if (evt && !/^[A-Za-z0-9][A-Za-z0-9.: ]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    },
    // letters, numbers, comma, colon, semi-colon, period, dash, asterisks, question marks, exclamation point, space
    alphanumericWithCustomSepCharacters(evt) {
      evt = (evt) || window.event;
      const expect = evt ? evt.target.value.toString() + evt.key.toString(): '';
      if (evt && !/^[A-Za-z0-9][A-Za-z0-9,;:.*?!\- ]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    }
  },
};
