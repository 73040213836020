import jwtDecode from 'jwt-decode';
import { getUserById } from '../api/users';
import { getUserRoleById } from '../api/userRole';
import { getPractices } from '../api/practice';
import { getUserPractices } from '../api/facilities';

export default {
  methods: {
    async getUserDetails() {
      if (!this.$storage.get('user')) {
        const decoded = jwtDecode(this.$storage.get('token'));
        await this.getUserById(decoded.UserId);
      }
      return this.$storage.get('user');
    },
    async getUserById(userid) {
      try {
        const user = await getUserById(userid);
        this.$storage.set('user', user);
      } catch (error) {
        window.console.error(error);
      }
    },
    async userRolePermissions() {
      try {
        const user = await this.getUserDetails();
        const role = await getUserRoleById(
          user.userRoleId,
          user.practiceId,
          user.facilityId,
        );
        this.$store.dispatch('getUserpermissions', role.roles);
      } catch (error) {
        window.console.error(error);
      }
    },
    async getUserPracticeName() {
      try {
        const user = await this.getUserDetails();
        if (!this.$store.state.userPracticeName) {
          const practices = await getPractices();
          const practice = practices.find((p) => p.id === user.practiceId);
          this.$store.dispatch('getUserPracticeName', practice.name);
        }
      } catch (error) {
        window.console.error(error);
      }
      return this.$store.state.userPracticeName;
    },
    async getUserFacilityName() {
      try {
        const user = await this.getUserDetails();
        if (!this.$store.state.userFacilityName) {
          const facilities = this.$storage.get('user-facilities') || await getUserPractices(user.userId);
          const facility = facilities.find((f) => user.facilityId === f.facilityId && user.practiceId === f.practiceId);
          this.$store.dispatch('getUserFacilityName', facility.facilityName);
        }
      } catch (error) {
        window.console.error(error);
      }
      return this.$store.state.userFacilityName;
    },
  },
};
