import axios from 'axios';
import consts from '../utils/consts';

import main from '../main';

axios.defaults.baseURL = consts.API_URL;

axios.interceptors.request.use(
  (response) => {
    const token = main.$storage.get('token');
    response.headers.common.Authorization = `Bearer ${token}`;
    main.$store.dispatch('showOverlay', true);
    // main.$Progress.start();
    return Promise.resolve(response);
  },
  (error) => {
    // main.$Progress.fail();
    main.$store.dispatch('showOverlay', false);
    main.$toasted.show(error?.response?.data?.title || error?.response?.data || error);
    return Promise.reject(error?.response?.data?.title || error?.response?.data || error);
  },
);

axios.interceptors.response.use(
  (response) => {
    main.$store.dispatch('showOverlay', false);
    // main.$Progress.finish();
    return Promise.resolve(response.data);
  },
  (error) => {
    // main.$Progress.fail();
    main.$store.dispatch('showOverlay', false);
    main.$toasted.show(error?.response?.data?.title || error?.response?.data || error);
    return Promise.reject(error?.response?.data?.title || error?.response?.data || error);
  },
);
