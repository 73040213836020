import axios from 'axios';

const addPractice = (payload) => axios.post('Practice/Add', payload);

const getPractice = () => axios.get('Practice/Get');

const getPracticeById = (PracticeId) =>
  axios.get(`Practice/GetbyId?PracticeId=${PracticeId}`);

const getPractices = () => axios.get('Practice/GetAll');

const updatePractice = (payload) => axios.put('Practice/Update', payload);

export { addPractice, getPractice, getPracticeById, getPractices, updatePractice };
