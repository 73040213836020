import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#225675', // '#007d98', // '#00867f', // '#48a290', // '#2094C8',
        secondary: '#3d8c95', // '#6dc3cc', // '#0c6f6a', // #424242',
        button: '#e5873c',
        accent: '#82B1FF',
        error: '#D62323',
        info: '#2196F3',
        success: '#19C93C',
        warning: '#FFC107',
        light: '#d6dfe4',
        table_hover: '#e2eeee',
        text_color: '#fff', // '#007d98', // '#fff', // '#48a290', // '#2094C8',
        link_color: 'rgb(0 0 0 / 87%)',
        radio_button_color: '#225675',
      },
      dark: {
        primary: '#30333a', // '#0F1642',
        secondary: '#01a26c', // '#6F64F8',
        button: '#e5873c',
        accent: '#82B1FF',
        error: '#D62323',
        info: '#2196F3',
        success: '#19C93C',
        warning: '#FFC107',
        light: '#d6dfe4',
        background: '#22252c', // '#2C3350',
        table_hover: '#2a2d34', // '#37368c',
        text_color: '#fff',
        link_color: '#fff',
        radio_button_color: '#01a26c',
      },
    },
    icons: {
      iconfont: 'mdi',
    },
  },
});
